.fas__menubar {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: white;
  border-radius: 12px;
  padding: 20px 0;
  width: 280px;
}

.fas__menu-username {
  margin-bottom: 20px;
}
