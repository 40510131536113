.fas__home-services {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;
}

.fas__home-service {
  position: relative;
  aspect-ratio: 3 / 2;
  border-radius: 12px;
  transition: 0.3s;
  font-size: 2rem;
  overflow: hidden;
}
.fas__home-service:hover {
  background-size: 110% !important;
}
.fas__home-service::before {
  position: absolute;
  content: "";
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}
.fas__home-service span {
  position: absolute;
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
}
