#fas__statisticcard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
}

.fas__statisticcard-item {
  position: relative;
  height: 120px;
  width: 250px;
  border-radius: 12px;
  color: var(--color-white);
  padding: 20px;
  margin: 0 10px 20px 10px;
  overflow: hidden;
}

.fas__statisticcard-item:last-child {
  margin-bottom: 50px;
}

.fas__statisticcard-item-orange {
  background: linear-gradient(90deg, #ff8a0099 0%, #ff8a00 100%);
}

.fas__statisticcard-item-yellow {
  background: linear-gradient(90deg, #f9bc1e99 0%, #f5b515 100%);
}

.fas__statisticcard-item-green {
  background: linear-gradient(90deg, #20b92d99 0%, #20b92d 100%);
}

.fas__statisticcard-item-blue {
  background: linear-gradient(90deg, #325fe899 0%, #325fe8 100%);
}

.fas__statisticcard-item-red {
  background: linear-gradient(90deg, #ea2c2c99 0%, #ea2c2c 100%);
}

.fas__statisticcard-item-grey {
  background: linear-gradient(90deg, #88888899 0%, #888888 100%);
}

.fas__statisticcard-title {
  font-size: 1.125rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.fas__statisticcard-item h1 {
  text-align: end;
  font-size: 2.5rem;
}

.fas__statisticcard-item .anticon {
  position: absolute;
  inset: auto auto -10px -6px;
  font-size: 80px;
  transform: rotate(-16deg);
  opacity: 0.3;
}
