#fas__account {
  display: flex;
  gap: 20px;
  width: 1300px;
  margin: 0 auto;
  padding: 20px 0;
}

.fas__account-left {
  width: 280px;
  flex-shrink: 0;
}

.fas__account-right {
  flex-grow: 1;
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  overflow: hidden;
}
