.fas__404 {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.fas__404 .ant-result-icon {
  margin: 0 auto;
}
