#fas__auth {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #fafbfc;
}

#fas__auth #fas__navbar {
  animation: auth_navbar_slidedown 0.8s ease-in-out;
}

.fas__auth-container {
  position: absolute;
  top: 15%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: max-content;
  width: 100%;
  z-index: 1;
}

.fas__auth-img {
  width: 400px;
  opacity: 0.8;
  animation: auth_floating 4s ease-in-out infinite;
}

.fas__auth-wave {
  position: absolute;
  bottom: 0;
}

.fas__auth-wave img {
  width: 100vw;
  vertical-align: top;
}

@keyframes auth_floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 12px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

@keyframes auth_navbar_slidedown {
  from {
    top: -50px;
  }
  to {
    top: 0;
  }
}
