#fas__login-form {
  box-shadow: var(--box-shadow);
  border-radius: 16px;
  padding: 20px;
  background-color: var(--color-white);
  width: 360px;
}

#fas__login-form h1 {
  text-align: center;
  margin-bottom: 30px;
}

#fas__login-form .ant-radio-group {
  margin-bottom: 24px;
  width: 100%;
  text-align: center;
}

.fas__login-form-button {
  width: 100%;
  margin-bottom: 24px;
}

#fas__login-form .ant-form-item:last-child {
  margin-bottom: 0;
}