.fas__dpage-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: var(--box-shadow);
}

.fas__dpage-text-title {
  font-size: 17px;
  font-weight: 600;
}

.fas__dpage-text p {
  margin-top: 10px;
}

.fas__dpage-text h2,
.fas__dpage-text h1 {
  color: var(--color-primary);
}

.fas__dpage-text h2 {
  margin-top: 20px;
}