#fas__footer {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-top: 1px solid var(--color-bg-grey);
}

.fas__footer-main {
  height: var(--height-footer-main);
  padding: 20px;
  display: flex;
  justify-content: space-around;
}

.fas__footer-main-logo {
  margin-bottom: 20px;
  height: 50px;
}

.fas__footer-main-logo img {
  height: 100%;
}

.fas__footer-main-desc {
  max-width: 400px;
  text-align: justify;
}

.fas__footer-main-right {
  display: flex;
}

.fas__footer-main-group:not(:last-child) {
  margin-right: 50px;
}

.fas__footer-main-group-title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 20px;
}

.fas__footer-main-group-link:not(:last-child) {
  margin-bottom: 16px;
}

.fas__footer-banner {
  background-color: var(--color-primary-bg);
  height: var(--height-footer-banner);;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.fas__footer-banner-social {
  display: flex;
}

.fas__footer-banner-social li:not(:last-child) {
  margin-right: 20px;
}

.fas__footer-banner-social span {
  vertical-align: top;
}

.fas__footer-banner-social svg {
  font-size: 24px;
}