:root {
  --color-primary: #ff8a00;
  --color-primary-bg: #fff7e6;
  --color-primary-border: #ffce7a;
  --color-primary-active: #d96c00;
  --color-primary-font: var(--color-white);
  --color-font: var(--color-black);
  --color-grey: #888;
  --color-black: #000;
  --color-black-transparent: #000000cc;
  --color-white: #fff;
  --color-white-transparent: #ffffffcc;
  --color-app-bg: #f6f6f6;
  --color-navbar-bg: var(--color-white-transparent);
  --color-footer-bg: #f0f0f0;
  --color-bg-grey: #e8e8e8;
  --color-input-bg: var(--color-white);
  --color-sidebar-bg: var(--color-white-transparent);
  --height-navbar: 50px;
  --height-footer: calc(
    var(--height-footer-main) + var(--height-footer-banner)
  );
  --height-footer-main: 255px;
  --height-footer-banner: 60px;
  --color-card-border: #e6edff;
  --card-border: 1px solid var(--color-card-border);
  --card-bg: var(--color-white);
  --animate-duration: 600ms;
  --animate-delay: 0.8s;
  --box-shadow: 0 2px 16px -3px rgba(0, 0, 0, 0.15);
  --box-shadow-button: 0 2px 6px -3px rgba(0, 0, 0, 0.3);
}

:root .dark {
  --color-primary: #dc7803;
  --color-primary-bg: #2c1d11;
  --color-primary-border: #5b370e;
  --color-primary-active: #ad6107;
  --color-font: var(--color-white);
  --color-app-bg: #1d2127;
  --color-navbar-bg: #31343ecc;
  --color-footer-bg: #31343e;
  --color-bg-grey: #3c404c;
  --color-input-bg: var(--color-black);
  --color-sidebar-bg: var(--color-black-transparent);
  --card-bg: var(--color-black);
  --box-shadow: 0 2px 16px -3px rgba(255, 255, 255, 0.15);
  --box-shadow-button: 0 2px 6px -3px rgba(255, 255, 255, 0.3);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  letter-spacing: 0.8px;
  list-style: none;
}

a {
  text-decoration: none;
  color: var(--color-font);
}
a.fas__link-text {
  transition: 0.3s;
  font-weight: bold;
}

a.fas__link-text:hover {
  color: var(--color-primary);
}

p {
  line-height: 1.6;
}

.fas__echarts {
  border: var(--card-border);
  background-color: var(--color-input-bg);
  padding: 20px;
  border-radius: 12px;
}

/* 表格 */
.table_action {
  color: var(--color-primary);
  cursor: pointer;
}
.table_img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

/* Dialogs, pop-ups */
.modal_item {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
}

.modal_item .ant-upload-wrapper {
  width: fit-content;
}

.modal_item label {
  width: 150px;
  margin-right: 20px;
}

.modal_item_photo {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.fas__loading {
  height: 100vh;
}

.fas__loading .ant-spin {
  height: 100vh !important;
  max-height: 100vh !important;
}

h1.fas__borderbottom {
  border-bottom: 2px solid var(--color-primary);
  width: fit-content;
  margin: 0 auto 40px;
  padding-bottom: 10px;
  font-size: 2rem;
}

.fas__font-georgia {
  font-family: Georgia;
  letter-spacing: 0.3px;
}

.ant-modal-content {
  background-color: #eee !important;
}

@keyframes fas_fadeInUp {
  from {
    transform: translateY(15px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fas_fadeInRight {
  from {
    transform: translateY(15px);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media screen and (max-width: 600px) {
  * {
    font-size: 14px;
  }
}
