.fas__detailspage {
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  width: 1100px;
}

.fas__product_detail {
  display: flex;
  align-items: center;
  gap: 40px;
}

.fas__product_detail_photo {
  width: 500px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  flex-shrink: 0;
}

.fas__product_detail_text {
  flex-grow: 1;
  height: 100%;
}

.fas__dp-provider {
  margin-top: 20px;
}

.fas__describe_review {
  margin-top: 20px;
}
