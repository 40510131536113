.fas__section {
  margin-bottom: 40px;
  text-align: center;
}

.fas__section h1 {
  border-bottom: 2px solid var(--color-primary);
  width: fit-content;
  margin: 40px auto;
  padding-bottom: 10px;
  font-size: 1.75rem;
}

.fas__home-carousel {
  height: 450px;
}

.fas__home-carousel-flex {
  display: flex;
  gap: 20px;
}

.fas__home-carousel img {
  width: 60%;
  height: 450px;
  object-fit: cover;
}

.fas__home-carousel-right {
  flex-grow: 1;
  background-color: var(--color-primary-bg);
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 50px;
}

.fas__home-carousel-right h1 {
  color: var(--color-primary);
  text-align: center;
}

.our_advantages_wrap {
  display: flex;
  justify-content: center;
}

.our_advantages_wrap .ant-space-item svg {
  height: 80px;
  width: 80px;
  color: var(--color-primary);
}
