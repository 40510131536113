#fas__navbar {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  height: var(--height-navbar);
  width: 100%;
  background-color: var(--color-navbar-bg);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  border-bottom: 1px solid var(--color-bg-grey);
}

.fas__navbar-logo {
  display: flex;
  align-items: center;
}

.fas__navbar-logo img {
  height: 45px;
  cursor: pointer;
}

.fas__navbar-func ul {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fas__navbar-func li {
  height: 100%;
  margin: 0 16px;
  display: flex;
  flex-direction: row;
}

.fas__dropdown {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.fas__navbar-avatar {
  margin-right: 10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--color-primary-border);
}

.fas__navbar-hello {
  line-height: 50px;
}
