#fas__backend {
  height: 100vh;
  width: 100vw;
}

.fas__backend-container {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.fas__backend-container > ul {
  margin-top: var(--height-navbar);
  height: calc(100% - var(--height-navbar));
}

.fas__backend-container > ul > li.ant-menu-item {
  margin-block: 20px;
}

.fas__backend-container .ant-menu-title-content {
  margin-inline-start: 20px !important;
}

.fas__backend-right {
  flex-grow: 1;
  padding-top: calc(var(--height-navbar) + 20px);
  overflow-y: auto;
}

.fas__backend-main  {
  min-height: calc(100vh - var(--height-navbar) - var(--height-footer-banner) - 20px);
  padding: 0 20px 40px;
}