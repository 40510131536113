#fas__servicelist {
  display: flex;
  gap: 20px;
  margin: 0 auto;
  padding: 20px 0;
  width: 1200px;
}

.fas__filter {
  flex-shrink: 0;
}

#fas__servicelist .ant-empty {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ant-spin {
  margin: auto;
}

.fas__servicelist {
  width: 900px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.fas__service {
  width: calc((100% - 40px) / 3);
  height: 320px;
}

.fas__service .ant-card-cover {
  aspect-ratio: 3 / 2;
  overflow: hidden;
}

.fas__service .ant-card-cover img {
  object-fit: cover;
}

.fas__service .ant-card-meta-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuservice {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  padding: 20px 0;
  width: 280px;
}
